<template>
    <modal ref="modal" @modal-closed="resetModal" with-dismiss>
        <div>
            <h3 class="text-lg leading-6 font-medium text-gray-900">
                Reset Password
            </h3>
            <p class="text-sm text-gray-500">
                Reset the user's password to a randomly generated password.
            </p>
            <div v-show="complete" class="mt-5 shadow-xs p-4 bg-green-100 rounded-md">
                <div class="flex">
                    <div class="shrink-0 text-green-600">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                    </div>
                    <div class="ml-3">
                        <h3 class="text-sm font-medium text-green-800">
                            Successfully saved!
                        </h3>
                        <p class="mt-2 text-sm text-green-800">
                            The user's password was reset successfully
                        </p>
                    </div>
                </div>
            </div>
            <div class="mt-5">
                <div v-if="password">
                    <div class="relative inline-block font-medium text-gray-600">
                        Random Password Generated
                    </div>
                    <copyable id="password" :value="password" :error="Boolean(error)" class="inline-block mt-5 shadow-xs py-6 px-4 items-center bg-gray-100 w-full rounded-md font-semibold tracking-[.1em]">
                        {{ password }}
                    </copyable>
                    <invalid-feedback :error="Boolean(error)" :message="error" />
                </div>
                <div v-else class="my-10 text-center">
                    <button-submit class="sm:w-auto w-full py-4 px-6" :processing="loading" @click="resetPassword" :disabled="loading || complete">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
                        </svg>
                        Generate User Password
                    </button-submit>
                </div>
            </div>
            <div class="mt-5 sm:mt-6 flex sm:flex-row sm:justify-end">
                <button @click="$refs.modal.close()" type="button" class="px-4 py-2 sm:mt-0 mt-3 text-base font-medium text-gray-500 hover:text-gray-800 focus:outline-hidden focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:text-sm">
                    Close
                </button>
            </div>
        </div>
    </modal>
</template>

<script>
import axios from 'axios';
import Modal from '@/components/layouts/Modal.vue';
import ButtonSubmit from '@/components/layouts/ButtonSubmit.vue';
import Copyable from '@/components/utils/CopyableItem.vue';
import InvalidFeedback from '@/components/layouts/InvalidFeedback.vue';

export default {
    name: 'ResetPasswordModal',

    props: {
        user: { required: true, type: Object }
    },

    components: {
        Modal,
        ButtonSubmit,
        InvalidFeedback,
        Copyable,
    },

    data: () => ({
        loading: false,
        password: null,
        complete: false,
        error: null,
    }),

    methods: {
        open(){
            this.$refs.modal.show();
        },

        async resetPassword() {
            this.loading = true;

            try {
                const {data} = await axios.post('/admin/accounts/reset-password', {user_id: this.user.id});

                this.password = data.new_password;
                this.complete = true;
                this.error = null;
            } catch ({response}) {
                if (response.status === 403) {
                    this.error = response.data.message;
                }

                console.error(response);
            }

            this.loading = false;
        },

        resetModal(){
            this.password = null;
            this.complete = false;
        },
    },
};
</script>
